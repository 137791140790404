:root {
	--black: hsl(0, 0%, 16%);
	--white: hsl(0,0%,97%);
	--blue: hsl(198, 100%, 66%);
	--teal: hsl(198, 43%, 42%);
	--lightYellow: hsl(43, 100%, 92%);
	--grey: hsl(0, 0%, 80%);
	--unit: 1.2rem;
	
	--chart-area: rgba(234, 0, 255, 0.78); /* #be07ce */
	--chart-line: rgba(234, 0, 255, 1);
	--chart-bg: #161118;
	
	--text-color: #fff;
}

html {
	background-color:#000000;
	font-family: "Source Sans Pro", sans-serif;
	font-size:14px;
	color:#ffffff;
	height:100%;
}

body, #root {
	background-color: #000000;
	margin:0;
	height:100%;
}

h1 {
	font-size:4rem;
	font-weight:bold;
	text-shadow: 0px 0px 1em #ff0000, 0px 0px 1em #ff0000;
}

p {
	line-height: 1.5rem;
}

a {
	color:#ffff00;
	text-decoration: none;
	border-bottom: 1px solid #ff9900;
}

a:hover {
	color:#ff9900;
}

.center {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-container {
	display: flex;
}

.chartcontainer {
	width:80vw; /* 250px */
	height: 80vh;
	max-height:1000px;
	/*height:100px;*/
	padding:20px;
	font-size:70px;
	font-weight: bold;
}

.chartcontainer canvas {
	background-color: var(--chart-bg);
	/*border: 2px solid #dddddd;*/
	/*box-shadow: 0px 0px 8px #dddddd;*/
	transition: box-shadow 0.5s ease-in-out;
}

#temperature {
	text-align: center;
}

#humidity {
	text-align: center;
}
